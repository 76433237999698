//把数组中的字符串类型的时间戳空缺的时间补全
import moment from 'moment'
export function completeDates(timestamps) {
  const dates = timestamps.map((timestamp) => moment.unix(Number(timestamp)).startOf('day'))
  // 找到最小日期和最大日期
  const minDate = moment.min(dates)
  const maxDate = moment.max(dates)

  // 计算最小日期和最大日期之间的天数差
  const daysDifference = maxDate.diff(minDate, 'days')

  // 生成补全的日期数组
  const completeTimestamps = [minDate.unix()]
  for (let i = 1; i <= daysDifference; i++) {
    const nextDate = minDate.clone().add(i, 'days')
    completeTimestamps.push(nextDate.unix())
  }
  return completeTimestamps || []
}