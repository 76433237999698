<template>
  <div class="monthScheduleTop">
    <div class="left">
      <div class="pickerBox" ref="pickerBoxRef">
        <i class="el-icon-date img"></i>
        <b>
          {{ timestampConvert() }}
        </b>
        <el-date-picker
          v-model="monthpickerVal"
          type="month"
          class="monthPicker"
          :clearable="false"
          v-bind="$attrs"
          @change="handlePanelChange"
          format="YYYY年MM月">
        </el-date-picker>
      </div>
      <div class="btn mr" @click.stop="handleLast">
        <i class="el-icon-arrow-left black"></i>
      </div>
      <div class="btn mr" @click.stop="handleNext">
        <i class="el-icon-arrow-right black"></i>
      </div>
      <div class="btn today" @click="handleToday()"> 今天 </div>
    </div>
    <div class="right">
      <i class="el-icon-refresh img" @click="handleRest"></i>
      <i v-if="!isFullscreen" class="el-icon-full-screen img" @click="handleFull(true)" ></i>
      <i v-else class="el-icon-rank img" style="transform: rotateZ(45deg);"  @click="handleFull(false)" ></i>
    </div>
  </div>
</template>

<script >
import moment from 'moment'
  export default {
    name: 'monthScheduleTop',
    data () {
      return {
        // calendarParameter:{
        //   timestamp:moment().unix()
        // },
        monthpickerVal:'',
        isFullscreen:false
      }
    },
    props: {
      courseCalendar:{
        type:HTMLDivElement ,
      },
      calendarParameter:{
        type:Object
      }
    },
    methods: {
      timestampConvert(){
        return moment.unix(this.calendarParameter.timestamp).format('YYYY年MM月')
      },
      handlePanelChange(){
        this.calendarParameter.timestamp = moment(this.monthpickerVal).unix()
        this.$emit('handleRenewal')
      },
      handleLast(){
        // if (appStore.getPageLoading) return
        this.calendarParameter.timestamp = moment
          .unix(this.calendarParameter.timestamp)
          .subtract(1, 'month')
          .unix()
       this.monthpickerVal = moment(
          moment.unix(this.calendarParameter.timestamp).format('YYYY/MM'),
          'YYYY年MM月'
        )
        this.$emit('handleRenewal')
      },
      handleNext(){
        // if (appStore.getPageLoading) return
        this.calendarParameter.timestamp = moment
          .unix(this.calendarParameter.timestamp)
          .add(1, 'month')
          .unix()
          this.monthpickerVal = moment(
          moment.unix(this.calendarParameter.timestamp).format('YYYY/MM'),
          'YYYY年MM月'
        )
        this.$emit('handleRenewal')
      },
      handleToday(){
        this.calendarParameter.timestamp = moment().unix()
        this.monthpickerVal = moment(
          moment.unix(this.calendarParameter.timestamp).format('YYYY/MM'),
          'YYYY年MM月'
        )
        this.$emit('handleRenewal')
      },
      handleFull(flg){
        if (!this.courseCalendar) return
        flg ? (this.courseCalendar.className = 'fullScreens') : (this.courseCalendar.className = 'monthSchedule')
        this.isFullscreen = flg
        this.$emit('fullscreen', this.isFullscreen)
      },
      handleRest(){
        this.handleToday()
      }
    },
    mounted () {
      this.monthpickerVal =  moment(moment.unix(this.calendarParameter.timestamp).format('YYYY/MM'), 'YYYY年MM月')
    }
  }
</script>

<style scoped lang="less">
  .monthScheduleTop{
    padding: 0 20px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    user-select: none;
    .left{
      height: 100%;
      display: flex;
      align-items: center;
      .pickerBox {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        .monthPicker {
          width: 118px;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -15px;
          opacity: 0;
          cursor: pointer;
        }
      }

      .img {
        font-size: 18px;
        margin-right: 5px;
      }
      b {
        font-size: 18px;
        margin-right: 5px;
      }
      .btn {
        padding: 0 4px;
        height: 30px;
        background: #eeeeee;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 5px;
        .black {
          font-size: 16px;
        }
      }
      .mr {
        margin-right: 5px;
      }
      .today {
        padding: 0 6px;
      }
    }
    .right {
      height: 100%;
      display: flex;
      align-items: center;
      .img {
        font-size: 18px;
        margin-right: 12px;
        cursor: pointer;
      }
    }
  }
</style>
