import moment from 'moment'
export default class Calendar {
  CalendarHeader = []
  FillAllData = []
  Timestamp = moment().unix()
  container = undefined
  isDragging = false
  mouseIndex = []
  MouseUpEventEmit = undefined
  allowClick = true
  constructor(timestamp) {
    this.Timestamp = timestamp
  }
  getCalendar = (month, year) => {
    const calendar = []
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth() + 1
    const currentDateNum = currentDate.getDate()
    const date = new Date(year, month - 1, 1)
    const firstDay = date.getDay()
    const lastDay = new Date(year, month, 0).getDate()
    const daysInWeek = 7

    const leadingBlanks = (firstDay - 1 + daysInWeek) % daysInWeek
    const trailingBlanks = daysInWeek - ((leadingBlanks + lastDay) % daysInWeek)

    const prevMonth = month === 1 ? 12 : month - 1
    const prevYear = month === 1 ? year - 1 : year
    const lastDayPrevMonth = new Date(prevYear, prevMonth, 0).getDate()

    calendar.push(
      ...Array.from({ length: leadingBlanks }, (_, i) => ({
        isNow: false,
        value: lastDayPrevMonth - i,
        timestamp: new Date(prevYear, prevMonth - 1, lastDayPrevMonth - i).getTime() / 1000
      }))
    )
    calendar.reverse()
    calendar.push(
      ...Array.from({ length: lastDay }, (_, i) => ({
        isNow: true,
        value: i + 1,
        timestamp: new Date(year, month - 1, i + 1).getTime() / 1000,
        today: month === currentMonth && i + 1 === currentDateNum && year === moment().year()
      }))
    )
    calendar.push(
      ...Array.from({ length: trailingBlanks }, (_, i) => ({
        isNow: false,
        value: i + 1,
        timestamp: new Date(year, month, i + 1).getTime() / 1000
      }))
    )
    return calendar
  }
  checkCrossDay(startTime, endTime) {
    const startMoment = moment.unix(startTime)
    const endMoment = moment.unix(endTime)

    // 判断开始时间和结束时间是否在同一天
    if (startMoment.isSame(endMoment, 'day')) {
      return [] // 不跨天，返回空数组
    }
    // 跨天情况下，计算跨越的天数
    const days = endMoment.diff(startMoment, 'days')
    const result = []

    for (let i = 0; i <= days; i++) {
      const currentDay = startMoment.clone().add(i, 'days')

      const startOfDay = currentDay.startOf('day').unix()
      const endOfDay = currentDay.endOf('day').unix()

      const start = i === 0 ? startTime : startOfDay
      const end = i === days ? endTime : endOfDay

      result.push({ start, end })
    }

    return result
  }
  setFillAllData(filData) {
    this.FillAllData = []
    this.FillAllData = filData
  }
  setupEventListeners(
    _container,
    mouseUpEvent
  ) {
    this.container = _container
    this.MouseUpEventEmit = mouseUpEvent
    if(!this.container) return
    this.container.addEventListener('mousedown', this.handleMouseDown.bind(this))
    this.container.addEventListener('mousemove', this.handleMouseMove.bind(this))
    this.container.addEventListener('mouseup', this.handleMouseUp.bind(this))
  }
  async handleMouseDown(event) {
    const clickedIndex = this.getDateFromMouseEvent(event)
    this.allowClick = true
    if (clickedIndex) {
      this.mouseIndex = []
      await this.restSelectedRange()
      this.mouseIndex.push(clickedIndex)
      this.mouseIndex = [...new Set(this.mouseIndex)]
      this.updateSelectedRange()
      this.isDragging = true
    }
  }

  handleMouseMove(event) {
    if (this.isDragging) {
      this.allowClick = false
      const hoveredIndex = this.getDateFromMouseEvent(event)
      event.preventDefault()
      if (hoveredIndex) {
        this.mouseIndex.push(hoveredIndex)
        this.mouseIndex = [...new Set(this.mouseIndex)]
        this.updateSelectedRange()
      }
    }
  }
  getDateFromMouseEvent(event) {
    // 返回对应的Date对象，如果超出有效区域则返回null
    if(event && event.srcElement && event.srcElement.getAttribute ){
      return event.srcElement.getAttribute('index')
    }
    return null
  }
  handleMouseUp() {
    this.isDragging = false
    this.allowClick ? '' : this.mouseUpEvent()
    setTimeout(() => {
      this.allowClick = true
    }, 0)
  }
  // 更新已选择日期范围的可视化表示
  updateSelectedRange() {}
  //重置日历数据为初始状态
  async restSelectedRange() {}
  mouseUpEvent() {}
}
