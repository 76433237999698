<template>
  <div class="moreTip">
    <div class="date" v-if="dayMore.timestamp">{{
      moment.unix(dayMore.timestamp).format('D')
    }}</div>
    <div class="tagBox" v-if="showdayMore">
      <div
        v-for="child in dayMore.fill"
        :key="child.id"
        @click="
          () => {
            handleClickTag(child)
          }
        "
      >
        <template v-if="child.type === EcourseType.course1v1">
          <SuccessTag
            v-bind="$attrs"
            :tagDetail="child" :monthCalendar="monthCalendar"
            v-if="child.type === EcourseType.course1v1 && child.status === COURSE_STATUS.accomplish"
          />
          <CancelTag
            v-bind="$attrs"
            :tagDetail="child" :monthCalendar="monthCalendar"
            v-else-if="
              child.type === EcourseType.course1v1 && child.status === COURSE_STATUS.cancel
            "
          />
          <subscribe
            :tagDetail="child" :monthCalendar="monthCalendar"
            v-else-if="
              child.type === EcourseType.course1v1 && child.status === COURSE_STATUS.subscribe
            "
          />
        </template>
        <NormalTag :tagDetail="child" v-else-if="child.type === EcourseType.vacate" />
        <ClassTag v-else-if="child.type === EcourseType.class" :tagDetail="child" />
      </div>
    </div>
  </div>
</template>

<script>
  import { COURSE_Type, EcourseType, COURSE_STATUS } from '@/enums/common.js'
  import CancelTag from './cancelTag.vue'
  import SuccessTag from './successTag.vue'
  import Subscribe from './subscribe.vue'
  import ClassTag from './classTag.vue'
  import NormalTag from './normalTag.vue'
  import moment from 'moment'
  export default {
    name:'moreTip',
    data(){
      return {
        moment,
        EcourseType,
        COURSE_Type,
        // dayMore:{
        //   fill:[]
        // },
        COURSE_STATUS,
        showdayMore:true
      }
    },
    components:{
      NormalTag,
      CancelTag,
      SuccessTag,
      Subscribe,
      ClassTag,
    },
    props:{
      monthCalendar:{
        type:Object
      },
      handleClickTag:{
        type:Function
      },
      dayMore:{
        type:Object
      }
    },
    methods: {
      setdayMore(dayMore){
        // this.dayMore = dayMore
        // this.$forceUpdate()
        // this.$nextTick(()=>{
        //   this.showdayMore = true
        //   console.log(this.dayMore ,'PPPPPPPPPPPPPPPPPPPPPPPPPPP')
        // })
      }
    }
  }
</script>

<style lang="less" scoped>
  .moreTip {
    width: 100%;
    .date {
      font-size: 18px;
      padding: 0 10px;
      margin-bottom: 6px;
    }
    .tagBox {
      width: 100%;
      max-height: 270px;
      overflow-y: auto;
      padding: 0 10px;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        opacity: 0.2;
        background: rgb(190 190 190 / 5%);
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgb(190 190 190 / 10%);
      }
    }
  }
</style>