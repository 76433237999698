<template>
  <el-checkbox-group v-model="checkCollect.soloCheckList">
    <el-checkbox v-for="item in soloOptions" :key="item.value" :label="item.value" :class="[item.tagColor]" @change="handleChange" >
      <span class="childCheck" :style="{color:item.color}" > {{ item.label }} </span>
    </el-checkbox>
  </el-checkbox-group>
</template>
<script>
  export default {
    name:'checkboxGroup',
    data(){
      return {

      }
    },
    props:{
      soloOptions:{
        type:Array
      },
      checkCollect:{
        type:Object
      }
    },
    methods: {
      handleChange(){
        this.$nextTick(()=>{
          if(!this.soloOptions) return
          this.checkCollect.soloCheckList.length === this.soloOptions.length ? this.$emit('checkAll',true) : this.$emit('checkAll',false)
        })
      },
      restStatus(){
        // this.$forceUpdate()
      }
    }
  }
</script>

<style lang="less" scoped>
  .childCheck{
    padding: 0 5px;
    font-size: 12px;
  }
</style>