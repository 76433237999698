<template>
  <div class="calendar">
    <div class="schedule" v-if="!isShowCalendar">
      <div class="top">
        <i class="iconfont  icon-papa-personal_scl" @click="handleIsShowCalendar">课程日历</i>
      </div>
      <div class="calendar-con">
        <div class="calendar-main">
          <div class="content">
            <div class="week">
              <div class="week-item" v-for="item in table.week" :key="item">{{ item }}</div>
            </div>
            <div class="day-box" v-show="viewDays.length > 0">
              <div class="row-group" style="border: none"
                   v-show="calendarStatus===1"
              >
                <div class="day" v-for="day in smallViewData" :key="day.date" :data-date="day.date"
                     @click="dClick(day)">

                  <div v-if="day.class==='after'||day.class === 'before'" class="con">
                    <div class="day-con" :class="day.class"> {{day.show}}</div>
                  </div>
                  <div v-else class="con" @click="chooseDay(day)">
                    <div class="day-con" :class="currentChooseDate == day.date?'active':''"> {{day.show}}</div>
                    <div v-if="day.data.length>0&&currentChooseDate!==day.date" class="point"></div>

                  </div>
                </div>
              </div>
              <div class="row-group" v-for="(week,index) in viewDays" :key="index" v-show="calendarStatus===0">
                <div class="day" v-for="day in week" :key="day.date" :data-date="day.date" @click="dClick(day)">

                  <div v-if="day.class==='after'||day.class === 'before'" class="con">
                    <div class="day-con" :class="day.class"> {{day.show}}</div>
                  </div>
                  <div v-else class="con" @click="chooseDay(day)">
                    <div class="day-con" :class="currentChooseDate == day.date?'active':''"> {{day.show}}</div>
                    <div v-if="day.data.length>0&&currentChooseDate!==day.date" class="point"></div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="month-view" v-if="calendarStatus===0">
          <div class="top">
            <div class="last-month">
              <i class="iconfont icon-papa-rt" @click="changeMonthView (-1)"></i>
            </div>
            <div class="current-month">{{currentChooseYear}}年{{currentChooseMonth}}月</div>
            <div class="next-month">
              <i class="iconfont icon-papa-right" @click="changeMonthView(1)"></i>

            </div>
          </div>
          <div class="current-day">
            {{currentChooseDay}}日
          </div>
          <div class="day-week">{{currentChooseDayInWeek}}</div>
          <div class="action">
            <div class="iconfont icon-papa-sanjiaoshang" @click="handleCalendarStatus(1)">收起</div>
          </div>
        </div>
        <div class="month-view collapse" v-else>
          <div class="top">
            <div class="last-month">
              <i class="iconfont icon-papa-rt" @click="changeMonthView (-1)"></i>
            </div>
            <div class="current-month">{{currentChooseYear}}年{{currentChooseMonth}}月</div>
            <div class="next-month">
              <i class="iconfont icon-papa-right" @click="changeMonthView(1)"></i>

            </div>
          </div>
          <div class="current-day">
            <div> {{currentChooseDay}}日</div>
            <div class="day-week">{{currentChooseDayInWeek}}</div>

          </div>
          <div class="action">
            <div class="show iconfont icon-papa-sanjiao_xia" @click="handleCalendarStatus(0)">展开</div>
          </div>
        </div>
      </div>
      <div class="course-list-box">
        <div class="list" v-if="currentDayCourse&&currentDayCourse.length>0">
          <div class="item" v-for="course in currentDayCourse" :key="course.id">
            <div class="course-title" v-if="course.course">
              <div class="title"><span
                  v-if="translateTag(course.course.category_id).abbr!=undefined">{{translateTag(course.course.category_id).abbr}}</span>{{course.course.name}}
              </div>
              <div class="desc" v-if="course.user">
                {{course.title}}
              </div>
              <div class="desc" v-else>
                班课 | {{course.title}}
              </div>
            </div>
            <div class="course-title" v-else>
              <div class="title"><span
                  v-if="translateTag(course.category_id).abbr!=undefined">{{translateTag(course.category_id).abbr}}</span>{{translateTag(course.category_id).name}}{{course.course_subject}}1v1
              </div>
              <div class="desc" v-if="course.course">
                班课 | {{course.title}}
              </div>
              <div class="desc" v-else>
                1v1{{course.teacher}}
              </div>
            </div>
            <div class="course-time">
              <div class="course-date">{{forMateMoment(course.started_at,'YYYY-MM-DD')}}</div>
              <div class="date-time">
                {{forMateMoment(course.started_at,'HH:mm')}}-{{forMateMoment(course.ended_at,'HH:mm')}}
              </div>
            </div>
            <div class="course-status" style="color: #ccc" v-if="course.status ===3">已取消</div>
            <!--            <div class="course-status" style="color: #ccc" v-if="course.status ===2">已结束</div>-->
            <div class="item_status" v-else-if="course.status === 2&&course.is_recorded">观看回放</div>
            <template v-else-if="course.status === 2&&!course.is_recorded">
              <div class="course_status" v-if="course.is_online_course">回放生成中</div>
              <div class="course_status" v-else>已完成</div>
            </template>
            <div class="course-status" v-if="course.status ===1">待上课</div>

            <div class="button" v-if="course.can_enter">
              <router-link :to="`/main/${course.id}`">
                进入教室
              </router-link>
            </div>
            <template v-if="course.course">
              <div class="item_button button button--small button--green"
                   @click="openLiveRoom(`/live/${course.id}`,2,course.id)"
                   v-if="course.status === 1">进入直播
              </div>

              <div class="item_button button button--small button--green"
                   v-else-if="course.status === 2&&course.is_recorded"
                   @click="openPage(`/course/replay/${course.id}`)">观看回放
              </div>
              <div class="item_button button button--small button--disable button--green"
                   v-else-if="course.status === 2&&!course.is_recorded">观看回放
              </div>
              <div class="item_button button button--small button--disable button--green"
                   v-else-if="course.status === 3">
                进入直播
              </div>

            </template>
            <template v-else>
              <div class="item_button button button--small button--green"
                   @click="courseFn(course)"
                   v-if="course.status === 1">进入直播
              </div>

              <div class="item_button button button--small button--green"
                   v-else-if="course.status === 2&&course.is_recorded"
                   @click="openPage(`/course/private/replay/${course.id}`)">观看回放
              </div>
              <div class="item_button button button--small button--disable button--green"
                   v-else-if="course.status === 2&&!course.is_recorded">观看回放
              </div>
              <div class="item_button button button--small button--disable button--green"
                   v-else-if="course.status === 3">
                进入直播
              </div>
            </template>

          </div>
        </div>
        <div class="no-course" v-else>
          这天没有课哦~
        </div>
      </div>
    </div>
    <div class="calendar-box" v-else>
      <PapaCalendar @getCalendarData="getCalendarData"
                    @changeView="handleIsShowCalendar"></PapaCalendar>
    </div>

  </div>
</template>

<script>
  import MyScheduleJS from './MySchedule.js'

  export default MyScheduleJS
</script>

<style lang="less" scoped>
  .calendar {
    width: 100%;

    & > .schedule {
      box-sizing: border-box;
      padding: 30px;

      & > .top {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        font-size: 16px;
        color: #555;
        cursor: pointer;
      }

      & > .calendar-con {
        width: 100%;
        display: flex;
        justify-content: space-between;

        & > .calendar-main {
          width: 66%;

          & > .content {
            width: 100%;
            /*height: 286px;*/
            box-sizing: border-box;
            display: table;
            border-collapse: collapse;
            border: 1px solid #e6e6e6;
            font-size: 14px;
            color: #333;

            & > .week {
              width: 100%;
              height: 38px;
              display: table;
              border-bottom: none;
              box-sizing: border-box;
              border-bottom: 1px solid #E6E6E6;


              & > .week-item {
                display: table-cell;
                line-height: 38px;
                text-align: center;
              }
            }

            & > .day-box {
              width: 100%;
              display: table;
              border-collapse: collapse;

              & > .row-group:last-child {
                border-bottom: none;
              }

              & > .row-group {
                display: table-row-group;
                border-bottom: 1px solid #E6E6E6;


                & > .day {
                  box-sizing: border-box;
                  /*border: 1px solid #E6E6E6;*/
                  /*width: 127px;*/
                  height: 50px;
                  line-height: 50px;
                  display: table-cell;
                  text-align: center;

                  & > .con {
                    height: 50px;
                    overflow-y: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    position: relative;

                    & > .day-con {
                      width: 30px;
                      height: 30px;
                      border-radius: 50%;
                      text-align: center;
                      line-height: 30px;
                      cursor: pointer;
                    }

                    & > .point {
                      position: absolute;
                      bottom: 10px;
                      width: 5px;
                      height: 5px;
                      border-radius: 50%;
                      background: red;
                    }

                    & > .active {
                      background: #29D087;
                      color: #fff;
                    }

                    .after, .before {
                      color: #8c939d;
                      cursor: not-allowed;
                    }

                    & > .list {
                      font-size: 12px;
                      white-space: nowrap;
                      width: 105px;
                      overflow-x: hidden;
                      cursor: pointer;
                    }

                    & > .green {
                      color: #309525;
                      background: #F4FDF8;
                    }

                    & > .blue {
                      color: #225895;
                      background: #F4FAFD;

                    }

                  }

                }
              }

            }
          }
        }

        & > .month-view {
          width: 30%;
          border: 1px solid #e6e6e6;
          position: relative;

          & > .top {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 35px;

            & > .current-month {
              /*margin: 0 10px;*/
            }

            .iconfont {
              font-size: 12px;
              cursor: pointer;
              padding: 0 10px;
            }

          }

          & > .current-day {
            margin-top: 60px;
            font-size: 32px;
            font-weight: 500;
            color: #333;
            text-align: center;
          }

          & > .day-week {
            margin-top: 20px;
            color: #666666;
            text-align: center;
            font-size: 14px;
          }

          & > .action {
            width: 58px;
            height: 24px;
            background: #E6E6E6;
            text-align: center;
            line-height: 24px;
            position: absolute;
            color: #999;
            right: 0;
            bottom: 0;
            cursor: pointer;

            & > div {
              font-size: 12px;
            }
          }
        }

        & > .collapse {
          & > .top {
            margin-top: 10px;
          }

          & > .current-day {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            & > .day-week {
              font-size: 14px;
              color: #666666;
              margin-left: 5px;

            }
          }

        }
      }

      & > .course-list-box {
        border: 1px solid #e6e6e6;
        margin-top: 30px;
        border-radius: 4px;
        padding: 0 30px;

        & > .list {
          & > .item:last-child {
            border-bottom: none;
          }

          & > .item {
            width: 100%;
            height: 108px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e6e6e6;
            justify-content: space-between;

            & > .course-title {
              width: 23%;
              color: #555;
              text-align: left;

              & > .title {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 10px;
              }

              span {
                background: #667482;
                color: #fff;
                padding: 1px 4px;
                margin-right: 8px;
                font-size: @fontSizeCon;
              }

              & > .desc {
                font-size: 12px;
              }
            }

            & > .course-time {
              text-align: center;
              color: #555;

              & > .course-date {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 10px;

              }

              & > .date-time {
                font-size: 12px;
              }
            }

            & > .course-status {
              font-size: 14px;
              font-weight: 500;
              color: #29D087;
            }

            & > .button {
              .button(90px, 36px);
              .button--green;

              & > a {
                color: #fff;
                font-size: 14px;
                text-decoration: none;
              }
            }
          }
        }

        & > .no-course {
          width: 100%;
          height: 150px;
          text-align: center;
          line-height: 150px;
          font-size: 16px;
        }
      }
    }

    & > .calendar-box {
      width: 100%;
    }
  }
</style>
