import Calendar from '@/components/Calendar_2/calendar'
import { Eweek } from '@/enums/common'
import moment from 'moment'
import { COURSE_STATUS } from '@/enums/common.js'
// import { useAppStoreWidthOut } from '/@/store/modules/app'
import { completeDates } from '@/utils/index'
import { useEventBus } from '@/utils/bus'
// const appStore = useAppStoreWidthOut()
const headerData = [
  {
    key: Eweek.Monday,
    label: '周一'
  },
  {
    key: Eweek.Tuesday,
    label: '周二'
  },
  {
    key: Eweek.Wednesday,
    label: '周三'
  },
  {
    key: Eweek.Thursday,
    label: '周四'
  },
  {
    key: Eweek.Friday,
    label: '周五'
  },
  {
    key: Eweek.Saturday,
    label: '周六'
  },
  {
    key: Eweek.Sunday,
    label: '周天'
  }
]
export class MonthCalendar extends Calendar {
  CalendarData = []
  constructor(timestamp) {
    super(timestamp)
    this.CalendarHeader = headerData
    this.setCalendarData(timestamp)
  }
  setCalendarData(timestamp, callback) {
    this.CalendarData = this.getCalendar(
      Number(moment.unix(timestamp).format('MM')),
      Number(moment.unix(timestamp).format('YYYY'))
    )
    this.FillAllData = []
    callback ? callback() : ''
    // appStore.setPageLoading(false)
  }
  recoverCalendarData() {
    this.CalendarData = this.CalendarData || []
    this.CalendarData = this.CalendarData.map((item) => {
      delete item.fill
      return item
    })
  }
  setCalendarFillData() {
    this.FillAllData = this.FillAllData || []
    if (this.FillAllData.length === 0 && this.CalendarData.length === 0) {
      // appStore.setPageLoading(false)
      return
    }
    this.CalendarData = this.CalendarData.map((item) => {
      this.FillAllData.map((child) => {
        const taught_at = child.taught_at || child.started_at
        const end = child.taught_finish_at || child.ended_at
        let crossDay = []
        taught_at && end ? (crossDay = this.checkCrossDay(taught_at, end)) : ''
        if (crossDay.length > 0) {
          crossDay.map((childer) => {
            if (
              childer.start &&
              item.timestamp &&
              moment.unix(item.timestamp).date() === moment.unix(childer.start).date() &&
              moment.unix(item.timestamp).month() === moment.unix(childer.start).month()
            ) {
              item.fill = item.fill || []
              item.fill.push({
                ...child,
                taught_at: childer.start,
                taught_finish_at: childer.end,
                ended_at: childer.end,
                started_at: childer.start
              })
            }
          })
        } else {
          if (
            taught_at &&
            item.timestamp &&
            moment.unix(item.timestamp).date() === moment.unix(taught_at).date() &&
            moment.unix(item.timestamp).month() === moment.unix(taught_at).month()
          ) {
            item.fill = item.fill || []
            item.fill.push(child)
          }
        }
      })
      if (item.fill) {
        item.fill.sort((a, b) => {
          const a_taught_at = a.taught_at || a.started_at
          const b_taught_at = b.taught_at || b.started_at
          const hourA = a_taught_at ? moment.unix(a_taught_at).hour() : 0
          const hourB = b_taught_at ? moment.unix(b_taught_at).hour() : 0
          return hourA - hourB
        })
      }
      return item
    })
    // appStore.setPageLoading(false)
  }
  isSameDate(timestamp1, timestamp2) {
    const date1 = moment.unix(timestamp1).date()
    const date2 = moment.unix(timestamp2).date()
    // let isSameDay = date1.isSame(date2, 'day');
    // let isSameMonth = date1.isSame(date2, 'month');
    // let isSameYear = date1.isSame(date2, 'year');
    return date1 === date2
  }
  setTime(applyTxt, props) {
    let txt = ''
    let taughtType = props.tagDetail['taught_at'] ? 'taught_at' : 'started_at'
    let endType = props.tagDetail['taught_finish_at'] ? 'taught_finish_at' : 'ended_at'
    txt =
      props.tagDetail && props.tagDetail[taughtType]
        ? moment.unix(props.tagDetail[taughtType]).format('HH:mm')
        : ''
    txt =
      txt +
      `-${
        props.tagDetail && props.tagDetail[endType]
          ? moment.unix(props.tagDetail[endType]).format('HH:mm')
          : ''
      }`
    return applyTxt + `<span class='time'> ${txt} </span>`
  }
  packageClassTag(TeacherCalendatInformation, props) {
    let applyTxt = ''
    applyTxt = this.setTime(applyTxt, props)
    TeacherCalendatInformation.detail.map((item) => {
      if (!item.check) return applyTxt
      if (item.key === 'courseType') {
        applyTxt = applyTxt + ' [班课] '
        return
      } else if (item.key.split('.').length > 1) {
        item.txt = props.tagDetail
          ? props.tagDetail[item.key.split('.')[0]]
            ? `${props.tagDetail[item.key.split('.')[0]][item.key.split('.')[1]]} `
            : ''
          : ''
      } else {
        item.txt = props.tagDetail ? `${props.tagDetail[item.key]} ` : ''
      }
      applyTxt = applyTxt + item.txt
    })
    if (applyTxt.endsWith('、')) {
      applyTxt = applyTxt.slice(0, -1)
    }
    return applyTxt
  }
  packageCourse1v1(TeacherCalendatInformation, props) {
    let applyTxt = ''
    applyTxt = this.setTime(applyTxt, props)
    TeacherCalendatInformation.detail.map((item) => {
      if (!item.check) return applyTxt
      if (item.key === 'courseType') {
        applyTxt = applyTxt + ' [1v1] '
        return
      } else if (item.key === 'periods') {
        if (props.tagDetail && props.tagDetail['is_audition']) {
          item.txt = '[试听]'
        } else if (props.tagDetail && props.tagDetail['is_first_course']) {
          item.txt = '[首课]'
        } else {
          if(props.tagDetail[item.key]) item.txt = props.tagDetail ? `[${props.tagDetail[item.key]}] ` : ''
        }
      } else if (item.key === 'status') {
        item.txt = props.tagDetail
          ? `${
              props.tagDetail[item.key] === COURSE_STATUS.cancel
                ? ' 已取消'
                : props.tagDetail[item.key] === COURSE_STATUS.accomplish
                ? ' 已完成'
                : ' 已预约'
            } `
          : ''
      } else if (item.key.split('.').length > 1) {
        item.txt = props.tagDetail
          ? props.tagDetail[item.key.split('.')[0]]
            ? `${props.tagDetail[item.key.split('.')[0]][item.key.split('.')[1]]} `
            : ''
          : ''
      } else {
        item.txt = props.tagDetail ? `${props.tagDetail[item.key]} ` : ''
      }
      item.txt ? applyTxt = applyTxt + item.txt : ''
    })
    if (applyTxt.endsWith('、')) {
      applyTxt = applyTxt.slice(0, -1)
    }
    return applyTxt
  }
  Bus = useEventBus()
  updateSelectedRange() {
    let _this = this
    completeDates(this.mouseIndex).map((_template) => {
      _this.CalendarData.forEach((item) => {
        item.timestamp === _template ? (item.activated = true) : ''
      })
      _this.Bus.$emit('calendarEntityUpdate', _this) 
    })
  }
  async restSelectedRange() {
    this.CalendarData.forEach((item) => {
      item.activated = false
    })
  }
  mouseUpEvent() {
    this.MouseUpEventEmit
      ? this.MouseUpEventEmit(this.CalendarData.filter((child) => child.activated))
      : ''
  }
}
