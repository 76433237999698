<template>
  <div class="monthHeader">
    <div class="listBox" v-for="item in monthCalendar.CalendarHeader" :key="item.key">
      {{ item.label }}
    </div>
  </div>
</template>

<script>
  export default {
    name:'Header',
    data(){
      return {
        monthCalendar:{
          CalendarHeader:[]
        }
      }
    },
     methods: {
      set_MonthCalendar(MonthCalendar){
        this.monthCalendar = MonthCalendar
      }
     }
  }
</script>

<style lang="less" scoped>
  .monthHeader {
    width: 100%;
    height: 40px;
    display: flex;
    background-color: #f5f7fa;
    justify-content: space-around;
    box-sizing: border-box;
    border-width: 0 1px 0 1px;
    border-style: solid;
    border-color: #f5f7fa;
    .listBox {
      width: 14.2857%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #fff;
    }
  }
</style>