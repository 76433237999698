<template>
  <div class="monthSchedule" ref="courseCalendar">
    <monthScheduleTop :calendarParameter="calendarParameter" :courseCalendar="$refs.courseCalendar"
                      @handleRenewal="handlefiltrateRenewal" @handleApiRenewal="handleApiRenewal"
                      @fullscreen="fullscreen" />
    <monthScheduleFiltrate
      ref="filtrateRef"
      @timeFiltrateData="timeFiltrateData"
      @handleRenewal="handlefiltrateRenewal"
      @userFiltrateData="userFiltrateData"
    />
    <calendar
      v-if="calendarShow"
      :class="[!isFullscreen ? 'calendarBox' : 'calendarFullBox']"
      :calendarType="2"
      ref="calendarRef"
      @handleDbclickCell="handleDbclickCell"
      @handleClickTag="handleClickTag"
      @mouseUpEvent="mouseUpEvent"
    />
  </div>
</template>

<script>
  import monthScheduleTop from './components/top.vue'
  import monthScheduleFiltrate from './components/filtrate.vue'
  import calendar from '@/components/Calendar_2/index.vue'
  import { EtimeInterval, EcourseType, COURSE_STATUS } from '@/enums/common.js'
  import * as Api from '@/api/user/user'
  import moment from 'moment'
  import { mapState } from 'vuex'

  export default {
    name: 'monthSchedule_2',
    components: {
      monthScheduleTop,
      monthScheduleFiltrate,
      calendar,
      // editDialog
    },
    data () {
      return {
        isFullscreen: false,
        studentId: 0,
        calendarParameter: {
          timestamp: moment().unix()
        },
        dataList: [],
        calendarShow: true,
        filterTimeArr: [],
        TearchCalendar: JSON.parse(localStorage.getItem('TearchCalendar') || '{}'),
      }
    },
    computed: {
      ...mapState({
        categoryList: state => state.common.categoryTypes
      })
    },
    methods: {
      fullscreen ( _fullscreen ) {
        this.isFullscreen = _fullscreen
      },
      async handleDateSelect ( dateObj ) {
        console.log('点击日期方格触发', dateObj)
        let record = {
          date: [
            moment.unix(dateObj.timestamp).format()
          ]
        }
        this.$refs.editDialogRef.setDialogShow(true, record)

        return
      },

      handleDbclickCell ( dateObj ) {
        this.handleDateSelect(dateObj)
      },
      handlefiltrateRenewal () {
        this.calendarShow = false
        this.$nextTick(() => {
          this.calendarShow = true
          this.$nextTick(() => {
            this.handleRenewal()
          })
        })
      },
      handleClickTag ( tagItem ) {
        if (tagItem.type == COURSE_STATUS.cancel) {
          let record = {
            date: [
              moment.unix(tagItem.started_at).format(),
            ],
            started_at: moment.unix(tagItem.started_at).format('HH:mm:00'),
            ended_at: moment.unix(tagItem.ended_at).format('HH:mm:00'),
            edit: true,
            id: tagItem.id,
            remark: tagItem.remark
          }
          this.$refs.editDialogRef.setDialogShow(true, record)
        }
      },
      handleApiRenewal () {
        this.calendarShow = false
        this.$nextTick(() => {
          this.calendarShow = true
          this.$nextTick(() => {
            this.$refs.filtrateRef.restFiltrateCondition()
            this.studentId = 0
            localStorage.removeItem('TearchCalendar')
            this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ] = null
            this.handleRenewal(true)
          })
        })

      },
      handleRenewal ( flg ) {
        !flg
          ? this.$refs.calendarRef.handleRenewal(this.calendarParameter.timestamp, this.renewal)
          : this.renewal()
      },
      renewal () {
        let _this = this
        this.dataList = []
        this.TearchCalendar = JSON.parse(localStorage.getItem('TearchCalendar') || '{}')
        let checkCollect = this.$refs.filtrateRef.getCheckCollect()
        this.get1v1Data(() => {
          this.filter1v1(checkCollect.soloCheckList)
          this.getClassData(() => {
            this.fillClass(checkCollect.checkClass)
            this.getVacate(() => {
              _this.fillVacate(checkCollect.checkVacate)
              let timeArea = _this.filterTimeArr
              timeArea.length > 0 ? (_this.dataList = _this.timeFiltrate(timeArea, _this.dataList)) : ''
              _this.studentId ? _this.userFilter(_this.studentId) : ''
              _this.$refs.calendarRef.setFillData(_this.dataList)

            })
          })
        })
      },
      filter1v1 ( soloCheckList ) {
        console.log("=>(monthSchedule_2.vue:136) soloCheckList", soloCheckList);

        if (soloCheckList.length === 0) {
          this.dataList =
            this.dataList.filter(( child ) => child.type !== EcourseType.course1v1) || []
          this.studentId ? this.userFilter(this.studentId) : ''
          return
        }
        if (
          this.TearchCalendar &&
          this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ]
        ) {
          this.dataList = this.dataList || []
          this.dataList = this.dataList.concat(
            this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ].filter(
              ( child ) => {
                return child.type === EcourseType.course1v1 && soloCheckList.includes(child.status)
              }
            )
          )
          this.studentId ? this.userFilter(this.studentId) : ''
        }
      },
      fillVacate ( flg ) {
        if (!flg) {
          this.dataList = this.dataList.filter(( child ) => child.type !== EcourseType.vacate) || []
          return
        }
        if (
          this.TearchCalendar &&
          this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ]
        ) {
          this.dataList = this.dataList || []
          this.dataList = this.dataList.concat(
            this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ].filter(
              ( child ) => {
                return child.type === EcourseType.vacate
              }
            )
          )
        }
      },
      fillClass ( flg ) {
        if (!flg) {
          this.dataList = this.dataList.filter(( child ) => child.type !== EcourseType.class) || []
          return
        }

        if (
          this.TearchCalendar &&
          this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ]
        ) {
          this.dataList = this.dataList || []
          this.dataList = this.dataList.concat(
            this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ].filter(
              ( child ) => {
                return child.type === EcourseType.class
              }
            )
          )
        }
      },
      setLocalstorage ( res ) {
        if (res && res.length > 0) {
          let _ts =
            this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ] || []
          _ts = _ts.concat(res)
          if (_ts.length === 0) return
          this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ] = _ts
        }
      },
      async get1v1Data ( callback ) {
        let _tsData = []
        if (this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ]) {
          _tsData = this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ].filter(
            ( child ) => child.type === EcourseType.course1v1
          ) || []
        }

        if (_tsData.length > 0) {
          callback ? callback() : ''
          return
        }
        Api.calendarPrivate({
          started_at: moment.unix(this.calendarParameter.timestamp).startOf('month').format('YYYY-MM-DD 00:00:00'),
          ended_at: moment.unix(this.calendarParameter.timestamp).endOf('month').format('YYYY-MM-DD 23:59:59'),
          is_reserved: 1,
          calendar_scope: 1,
          teacher_id: 1
        }, res => {
          this.dataList = this.dataList || []
          res = res.map(( item ) => {
            item.type = EcourseType.course1v1
            item.category = this.categoryList.find(( child ) => child.id === item.category_id).name
            return item
          })
          this.setLocalstorage(res)
          callback ? callback() : ''
        })

      },
      async getClassData ( callback ) {
        let _tsData = []
        if (this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ]) {
          _tsData = this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ].filter(
            ( child ) => child.type === EcourseType.class
          ) || []
        }

        if (_tsData.length > 0) {
          callback ? callback() : ''
          return
        }
        Api.calendar({
          started_at: moment.unix(this.calendarParameter.timestamp).startOf('month').format('YYYY-MM-DD 00:00:00'),
          ended_at: moment.unix(this.calendarParameter.timestamp).endOf('month').format('YYYY-MM-DD 23:59:59'),
          is_reserved: 1,
          teacher_id: 1
        }, res => {
          res = res || []
          res = res.map(( item ) => {
            item.type = EcourseType.class
            return item
          })
          this.setLocalstorage(res)
          callback ? callback() : ''
        })

      },
      async getVacate ( callback ) {
        let _tsData = []
        if (this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ]) {
          _tsData = this.TearchCalendar[ moment.unix(this.calendarParameter.timestamp).format('YYYY/MM') ].filter(
            ( child ) => child.type === EcourseType.vacate
          ) || []
        }

        if (_tsData.length > 0) {
          callback ? callback() : ''
          return
        }

        callback ? callback() : ''

      },
      timeFiltrateData ( objArr ) {
        this.filterTimeArr = objArr
        this.renewal()
      },
      timeFiltrate ( objArr, filtrateBefore ) {
        let tsData = []
        objArr.map(( obj ) => {
          let tsSection = EtimeInterval.filter(( child ) => {
            return child.value === obj
          })[ 0 ] || undefined
          if (!tsSection) return
          tsData = tsData.concat(
            filtrateBefore.filter(( item ) => {
              let taught_at = item.taught_at || item.started_at
              let taught_finish_at = item.taught_finish_at || item.ended_at
              return (
                taught_at &&
                taught_finish_at &&
                moment(moment.unix(taught_at).format('HH:mm'), 'HH:mm').isSameOrAfter(
                  moment(tsSection.start, 'HH:mm')
                ) &&
                moment(moment.unix(taught_at).format('HH:mm'), 'HH:mm').isBefore(
                  moment(tsSection.end, 'HH:mm')
                )
              )
            })
          )
        })
        return tsData.filter(( item, index ) => tsData.findIndex(( i ) => i.id === item.id) === index)
      },
      userFiltrateData ( val ) {
        this.studentId = val
        this.renewal()
        if (val) {
          this.userFilter(val)
          this.$refs.calendarRef.setFillData(this.dataList)
          return
        }
      },
      userFilter ( val ) {
        this.dataList = this.dataList.filter(( child ) => {
          return child.teacher === val
        })
      },
      mouseUpEvent ( activeArr ) {
        if (!(activeArr[ 0 ] && activeArr[ 0 ].timestamp)) return
        let record = {
          date: activeArr.map(item => moment.unix(item.timestamp).format())
        }
        this.$refs.editDialogRef.setDialogShow(true, record)
        return
      },
      getCalendarData () {

      }
    },
    destroyed () {
      localStorage.removeItem('TearchCalendar')
    },
    mounted () {
      localStorage.removeItem('TearchCalendar')
    }
  }
</script>

<style scoped lang="less">
  .monthSchedule {
    background: #fff;
  }

  .calendarBox {
    width: 100%;
    // height: calc(100vh - 450px);
  }

  .calendarFullBox {
    width: 100%;
    height: calc(100% - 100px);
  }
</style>
<style lang="less">
  .fullScreens {
    position: fixed;
    left: 0px;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 99999;
  }
</style>
