<template>
  <div>
    222
  </div>
</template>

<script>
  export default {
    name:'week',
    data(){
      return {

      }
    }
  }
</script>

<style lang="less" scoped>

</style>