import { formatMoment, openLiveRoom, openPage } from '@/utils/utils'
import * as Api from '@/api/user/user'
import PapaCalendar from './monthSchedule_2.vue'
// import PapaCalendar from '@/components/Calendar/Calendar.vue'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      currentChooseObj: {
        date: '',
        data: []
      },
      isShowCalendar: false,
      calendarData: [],
      dayInLine: '',
      calendarStatus: 1, // 0 展开 1收起
      currentChooseDate: '',
      currentChooseDay: '',
      currentChooseMonth: '',
      currentChooseYear: '',
      currentChooseDayInWeek: '',
      currentDayCourse: [],
      table: {
        size: {
          row: 6,
          column: 7
        },
        week: ['一', '二', '三', '四', '五', '六', '日',]
      },
      currentTime: '',
      currentYear: '',
      currentMonth: '',
      currentDate: '',
      leapYear: '',
      firstDayofWeek: '',
      daysInMonth: [],
      showLastMonthDays: [],
      showNextMonthDays: [],
      showCurrentMonthDays: [],
      viewDays: [],
      course: [],
      isShowMonthBox: false,
      monthData: [
        {
          name: '一月',
          id: 0
        },
        {
          name: '二月',
          id: 1
        },
        {
          name: '三月',
          id: 2
        },
        {
          name: '四月',
          id: 3
        },
        {
          name: '五月',
          id: 4
        },
        {
          name: '六月',
          id: 5
        },
        {
          name: '七月',
          id: 6
        },
        {
          name: '八月',
          id: 7
        },
        {
          name: '九月',
          id: 8
        },
        {
          name: '十月',
          id: 9
        },
        {
          name: '十一月',
          id: 10
        },
        {
          name: '十二月',
          id: 11
        },
      ],
      showTimeLine: false,
      activeDate: '',
      firstRender: true
    }
  },
  computed: {
    smallViewData () {
      let data = []
      this.viewDays.forEach((ele, index) => {
        if (index === this.dayInLine) {
          data = ele
        }
      })
      return data
    }
  },
  mounted () {
    this.init()
  },
  updated () {
    if (this.firstRender) {
      let date = `${this.currentYear}-${this.makeUpZero(this.currentMonth + 1)}-${this.makeUpZero(this.currentDate)}`
      this.viewDays.forEach((ele, index) => {
        ele.forEach((day) => {
          if (day.date === date) {
            this.chooseDay(day)
            this.dayInLine = index
            this.firstRender = false
          }
        })
      })
    }

  },
  methods: {
    init () {
      this.currentTime = new Date()
      this.currentYear = this.currentTime.getFullYear()
      this.currentMonth = this.currentTime.getMonth()
      this.currentDate = this.currentTime.getDate()
      this.currentChooseObj.date = `${this.currentYear}-${this.makeUpZero(this.currentMonth + 1)}-${this.makeUpZero(this.currentDate)}`
      this.isLeapYear(this.currentYear)
      this.getDaysInMonth()
      this.getFirstDayOfMonth()
      this.calcDays(this.currentMonth)
    },
    isLeapYear (year) {
      return this.leapYear = (year % 400 === 0) || ((year % 4 === 0) && (year % 100 !== 0))
    },
    getDaysInMonth () {
      return this.daysInMonth = [31, this.isLeapYear(this.currentYear) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    },
    getFirstDayOfMonth (month = this.currentMonth, year = this.currentYear) {
      let firstDay = new Date(year, month, 1)
      let day = firstDay.getDay()
      this.firstDayofWeek = day === 0 ? 7 : day
    },
    calcDays (currentMonth) {
      let lastMonth = ''
      if (currentMonth === 11) {
        lastMonth = currentMonth - 1
      } else if (currentMonth === 0) {
        lastMonth = 11
      } else {
        lastMonth = currentMonth - 1
      }
      this.showLastMonthDays = []
      this.showCurrentMonthDays = []
      this.showNextMonthDays = []
      let lastMonthDays = this.daysInMonth[lastMonth]
      this.showLastMonthDays = []
      for (let i = lastMonthDays; i > lastMonthDays - Math.abs(1 - this.firstDayofWeek); i--) {
        let obj = {}
        obj.show = i
        if (this.currentMonth == 0) {
          obj.date = `${this.currentYear - 1}-${this.makeUpZero(12)}-${this.makeUpZero(i)}`
        } else {
          obj.date = `${this.currentYear}-${this.makeUpZero(this.currentMonth)}-${this.makeUpZero(i)}`
        }
        obj.data = []
        obj.class = 'before'
        this.showLastMonthDays.unshift(obj)
      }
      for (let i = 1; i <= this.daysInMonth[currentMonth]; i++) {
        let obj = {}
        obj.show = i
        obj.data = []
        obj.date = `${this.currentYear}-${this.makeUpZero(this.currentMonth + 1)}-${this.makeUpZero(i)}`
        this.showCurrentMonthDays.push(obj)

      }
      for (let i = 1; i <= 42 - this.daysInMonth[currentMonth] - this.showLastMonthDays.length; i++) {
        let obj = {}
        if (i === 1) {
          if (currentMonth === 11) {
            obj.show = '1'
            obj.date = `${this.currentYear + 1}-01-${this.makeUpZero(i)}`
          } else {
            // obj.show = `${currentMonth + 2}月`
            obj.show = '1'
            obj.date = `${this.currentYear}-${this.makeUpZero(this.currentMonth + 2)}-${this.makeUpZero(i)}`
          }
        } else {
          if (currentMonth === 11) {
            obj.show = i
            obj.date = `${this.currentYear + 1}-01-${this.makeUpZero(i)}`
          } else {
            obj.show = i
            obj.date = `${this.currentYear}-${this.makeUpZero(this.currentMonth + 2)}-${this.makeUpZero(i)}`
          }
        }
        obj.class = 'after'
        obj.data = []
        this.showNextMonthDays.push(obj)
      }
      let dayArr = this.showLastMonthDays.concat(this.showCurrentMonthDays).concat(this.showNextMonthDays)

      this.getCalendarData(dayArr[0].date, dayArr[41].date).then(res => {
        let dayDataArr = this.renderData(dayArr, res)
        let dateArr = []
        for (let i = 0; i < dayDataArr.length; i += 7) {
          dateArr.push(dayArr.slice(i, i + 7))
        }
        this.$nextTick(() => {
          this.viewDays = []
          this.viewDays = dateArr

          this.viewDays[this.dayInLine] && this.viewDays[this.dayInLine].forEach(ele => {
            if (ele.date === this.currentChooseObj.date) {
              this.currentChooseObj.data = ele.data
              this.chooseDay(this.currentChooseObj)
            }
          })
        })
      })
    },
    handleMonthBox () {
      this.isShowMonthBox = true
    },
    changeMonth (month) {
      this.getFirstDayOfMonth(month)
      this.viewDays = []
      this.isShowMonthBox = false
      this.currentMonth = month
      this.getFirstDayOfMonth()
      this.calcDays(month)
    },
    renderData (arr, data) {
      data.sort(this.compare('started_at'))
      arr.forEach(item => {
        data.forEach(ele => {
          if (item.date == this.forMateMoment(ele.started_at, 'YYYY-MM-DD')) {
            ele.color = 'green'
            item.data.push(ele)
          }
        })
      })
      return arr
    },
    makeUpZero (num) {
      return num >= 10 ? num : '0' + num
    },
    dClick (day) {
      this.showTimeLine = true
      this.activeDate = day.date
    },
    cancleTimeLine () {
      this.showTimeLine = false
    },
    changeYear (num) {
      this.currentYear = this.currentYear + num * 1
      this.getDaysInMonth()
      this.getFirstDayOfMonth()
    },
    handleLeftBtn () {
      this.$emit('changeView')
    },
    chooseDay (day) {
      this.currentDayCourse = day.data
      this.currentChooseDate = day.date
      let chooseDateArr = this.currentChooseDate.split('-')
      this.currentChooseDay = chooseDateArr[2]
      this.currentChooseMonth = chooseDateArr[1]
      this.currentChooseYear = chooseDateArr[0]
      let week = ['日', '一', '二', '三', '四', '五', '六']
      this.currentChooseDayInWeek = '周' + week[new Date((Date.parse(day.date))).getDay()]
    },
    changeMonthView (type) {
      let currentDateArr = this.currentChooseDate.split('-')
      if (type === -1) {
        if (currentDateArr[1] === '01') {
          this.currentChooseMonth = 12
          this.currentChooseYear = parseInt(currentDateArr[0]) - 1
          this.changeYear(-1)
        } else {
          this.currentChooseMonth = this.makeUpZero(parseInt(currentDateArr[1]) - 1)
        }
      } else {
        if (currentDateArr[1] === '12') {
          this.currentChooseMonth = this.makeUpZero(1)
          this.currentChooseYear = parseInt(currentDateArr[0]) + 1
          this.changeYear(1)
        } else {
          this.currentChooseMonth = this.makeUpZero(parseInt(currentDateArr[1]) + 1)
        }
      }
      this.changeMonth(this.currentChooseMonth - 1)
      this.chooseDay({date: `${this.currentChooseYear}-${this.currentChooseMonth}-${this.currentChooseDay}`})
    },
    handleCalendarStatus (status) {
      this.calendarStatus = status
    },
    handleIsShowCalendar () {
      this.isShowCalendar = !this.isShowCalendar
    },
    forMateMoment (time, type) {
      return formatMoment(time, type)
    },

    getCalendarData (start, end) {
      const start_stamp = new Date(start).getTime() / 1000
      const end_stamp = new Date(end).getTime() / 1000
      let params = {
        between_at: [
          start_stamp,end_stamp
        ],
      }
      return new Promise((resolve, reject) => {
        Api.calendar(params, (res) => {
          let obj = res
          Api.calendarPrivate(params, res => {
            obj = obj.concat(res)
            this.calendarData = obj
            this.setCalendarData(obj)
            resolve(obj)
          })
        })
      })
    },
    translateTag (id) {
      let types = this.$store.state.common.categoryTypes
      let tag = types.find(item => {
        return item.id === id
      })
      return tag || undefined
    },
    compare (property) {
      return function (a, b) {
        let value1 = a[property]
        let value2 = b[property]
        return value1 - value2
      }
    },
    courseFn(course) {
      let _this = this
      openLiveRoom(`/private/live/${course.id}`,1,course.id, this.$route, _this)
    },
    openLiveRoom,
    openPage,
    ...mapActions([
      'setCalendarData'
    ])
  },
  components: {
    PapaCalendar
  },
  watch: {}
}
