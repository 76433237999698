<template>
  <div class="month">
    <Header class="header" ref="headerRef" />
    <Entity class="entity" v-bind="$attrs" ref="entityRef"
      :monthCalendar="monthCalendar"
      @handleDbclickCell="handleDbclickCell"
      @handleClickTag="handleClickTag"
      @mouseUpEvent="mouseUpEvent" />
  </div>
</template>

<script>
  import Header from './components/header.vue'
  import Entity from './components/entity.vue'
  import { MonthCalendar } from './month.js'
  import moment from 'moment'
  export default {
    name:'',
    components:{
      Header,
      Entity
    },
    data(){
      return {
        // eslint-disable-next-line vue/no-reserved-keys
        monthCalendar:{},
        debounceTimer:null
      }
    },
    methods: {
      handleRenewal(timestamp,callback){
        if (timestamp) {
          this.monthCalendar.CalendarData.value = []
          this.monthCalendar.setCalendarData(timestamp, callback)
        }
      },
      onClickDebounced(callback) {
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer)
        }
        this.debounceTimer = setTimeout(() => {
          callback ? callback() : ''
          this.debounceTimer = null
        }, 500)
      },
      setData(arr) {
        this.monthCalendar.setFillAllData(arr)
        this.monthCalendar.recoverCalendarData()
        // appStore.setPageLoading(true)
        this.onClickDebounced(() => {
          this.$nextTick(() => {
            this.$refs.entityRef.setData()
            // appStore.setPageLoading(false)
          })
        })
        //
      },
      handleDbclickCell(dateObj){
        this.$emit('handleDbclickCell', dateObj)
      },
      handleClickTag(tagItem){
        this.$emit('handleClickTag', tagItem)
      },
      mouseUpEvent(activeData){
        this.$emit('mouseUpEvent', activeData)
      },
    },
    mounted () {
      this.monthCalendar = new MonthCalendar(moment().unix())
      this.$refs.headerRef.set_MonthCalendar(this.monthCalendar)
      this.$refs.entityRef.set_MonthCalendar(this.monthCalendar)
    }
  }
</script>

<style lang="less" scoped>
  .month {
    width: 100%;
    height: 100%;
    .entity {
      height: calc(100% - 40px);
       overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px; /* 滚动条宽度 */
      }
    }
  }
</style>
