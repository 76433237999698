<template>
  <dl class="filtrate">
    <dt>
      <el-checkbox
        class="checkAll"
        v-model="checkCollect.checkAll"
        :indeterminate="indeterminate"
        @change="onCheckAllChange"
      >
        全选
      </el-checkbox>
      <div class="checkAll solo">
        <el-checkbox
          v-model="checkCollect.checkAllSolo"
          :indeterminate="indeterminate"
          @change="onCheckAllSoloChange"
        >
          1v1课程
        </el-checkbox>
        <i class="el-icon-d-arrow-right doubleRightOutlined"></i>
        <CheckboxGroup :soloOptions="soloOptions" :checkCollect="checkCollect" @checkAll="soloCheckAll" ref="checkboxGroupRef" />
      </div>
      <el-checkbox
        class="class"
        v-model="checkCollect.checkClass"
        :indeterminate="indeterminate"
        @change="
          () => {
            onCheckChange(EcourseType.class)
          }
        "
      >
        班课
      </el-checkbox>
    </dt>
    <dd>
      <div class="dropdown">
        <Dropdowns @handleRenewal="handleRenewal" ref="dropdownRef" />
      </div>
      <div class="searchParams-box">
        <el-input size="small" class="searchParams searchParams-teacher" clearable v-model="searchParams.teacher_name" placeholder="搜索教师名" @change="onTeacherNameChange"  />
        <el-select size="small" class="searchParams" v-model="searchParams.date" multiple collapse-tags placeholder="请选择时间段"
        @change="onTimeRangeChange">
          <el-option
            v-for="item in dateTimeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
    </dd>
  </dl>
</template>

<script>
  import { COURSE_STATUS,EcourseType, EdayType } from '@/enums/common.js'
  import CheckboxGroup from './checkboxGroup.vue'
  import Dropdowns from './dropdown.vue'
  export default {
    name:'filtrate',
    data(){
      return {
        checkCollect:{
          checkAll: false,
          checkVacate: false,
          checkClass: false,
          checkAllSolo: false,
          soloCheckList: [COURSE_STATUS.subscribe]
        },
        timeArea:[],
        TeacherCalendatCondition:localStorage.getItem('TeacherCalendatCondition'),
        indeterminate:false,
        soloOptions:[
          {
            label: '已预约',
            value: COURSE_STATUS.subscribe,
            color: '#FFC74F',
            tagColor: 'yellow'
          },
          {
            label: '已完成',
            value: COURSE_STATUS.accomplish,
            color: '#29D087',
            tagColor: 'green'
          },
          // {
          //   label: '已取消',
          //   value: COURSE_STATUS.cancel,
          //   color: '#FF6A6A',
          //   tagColor: 'red'
          // }
        ],
        EcourseType,
        searchParams:{
          teacher_name: ''
        },
        dateTimeOptions: [
          {
            value:'00:00 ~ 06:00',
            key:EdayType.Dawn,
            start:'00:01',
            end:'06:00'
          },
          {
            value:'06:00 ~ 12:00',
            key:EdayType.Am,
            start:'06:00',
            end:'12:00'
          },
          {
            value:'12:00 ~ 18:00',
            key:EdayType.Pm,
            start:'12:00',
            end:'18:00'
          },
          {
            value:'18:00 ~ 24:00',
            key:EdayType.Night,
            start:'18:00',
            end:'23:59'
          }
        ]
      }
    },
    components:{
      CheckboxGroup,
      Dropdowns
    },
    mounted () {
      if (this.TeacherCalendatCondition) {
        this.checkCollect = JSON.parse(this.TeacherCalendatCondition)
      }
      this.$nextTick(()=>{
        this.$emit('handleRenewal')
      })
    },
    methods: {
      onCheckAllChange() {
        this.$nextTick(() => {
          this.checkCollect.checkAllSolo = this.checkCollect.checkAll
          this.checkCollect.checkClass = this.checkCollect.checkAll
          this.checkCollect.checkVacate = this.checkCollect.checkAll
          this.onCheckAllSoloChange()
        })
      },
      onCheckAllSoloChange() {
        this.checkCollect.soloCheckList = !this.checkCollect.checkAllSolo
          ? []
          : [COURSE_STATUS.subscribe, COURSE_STATUS.accomplish, COURSE_STATUS.cancel]
        this.judgecheckAll()
        this.$refs.checkboxGroupRef.restStatus()
        // this.$forceUpdate()
        this.$emit('handleRenewal', true)
      },
      soloCheckAll(flg) {
        this.checkCollect.checkAllSolo = flg
        this.judgecheckAll()
        this.$emit('handleRenewal', true)
      },
      onCheckChange() {
        this.judgecheckAll()
        this.$emit('handleRenewal', true)
      },
      judgecheckAll() {
        this.checkCollect.checkAll =
        this.checkCollect.checkAllSolo &&
        this.checkCollect.checkClass &&
        this.checkCollect.checkVacate
            ? true
            : false
        this.setLocal()
      },
      setLocal() {
        localStorage.setItem('TeacherCalendatCondition', JSON.stringify(this.checkCollect))
      },
      handleRenewal() {
        this.$emit('handleRenewal')
      },
      getCheckCollect() {
        return this.checkCollect
      },
      getCheckTimeArea() {
        return this.timeArea
      },
      setCheckCollect(key, val) {
        this.checkCollect[key] = val
      },
      restFiltrateCondition() {
        this.timeArea = []
        this.$refs.dropdownRef.handleRest()
      },
      onTeacherNameChange(val){
        console.log(val)
        this.$emit('userFiltrateData', val)
      },
      onTimeRangeChange(val){
        console.log(val)
        this.$emit('timeFiltrateData', val)
      }
    }
  }
</script>

<style lang="less" scoped>
  .filtrate {
    height: 40px;
    padding: 0 20px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    :deep(.ant-checkbox + span) {
      padding-left: 0;
    }
    dd{
      margin: 0;
    }
    dt {
      height: 100%;
      display: flex;
      flex: 1;
      align-items: center;
      .checkAll {
        padding-right: 16px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          right: 12px;
          top: 50%;
          margin-top: -10px;
          width: 1px;
          height: 20px;
          background: #eee;
        }
      }

      .yellow {
        :deep(.ant-checkbox-inner) {
          border-color: #ffc74f;
        }
      }
      .green {
        :deep(.ant-checkbox-inner) {
          border-color: #29d087;
        }
      }
      .red {
        :deep(.ant-checkbox-inner) {
          border-color: #ff6a6a;
        }
      }
      .doubleRightOutlined {
        font-size: 12px;
        margin-right: 5px;
      }
      .class {
        color: #108ee9;
        :deep(.ant-checkbox-inner) {
          border-color: #108ee9;
        }
      }
    }
    dd {
      min-width: 10%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      :deep(.ant-form-item) {
        margin-bottom: 0;
      }
      .dropdown {
        width: 150px;
        height: 32px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #eeeeee;
        border-radius: 2px;
        margin-right: 10px;
        z-index: 1;
      }
      .searchParams-box{
        display: flex;
        .searchParams{
          width: 180px;
        }
        .searchParams-teacher{
          margin-right: 10px;
          width: 150px;
        }
      }
      .formBox {
        flex: auto;
        margin-right: 10px;
      }
    }
    :deep(.ant-checkbox-wrapper) {
      font-size: 12px;
    }
  }
  .solo{
    display: flex;
    align-items: center;
  }
</style>
